import { createContext, useContext } from 'react';

// Define our theme context
const SectionContext = createContext('Light');

// Define a function to use our theme
export function useThemeSection() {
    return useContext(SectionContext);
}

// Create a ThemeProvider component
export function SectionProvider({ theme, children }: { theme: string; children: React.ReactNode }) {
    return <SectionContext.Provider value={theme}>{children}</SectionContext.Provider>;
}
